<!--
  Allow for upload of activity via a file
-->
<template>
  <div>
    <b-field class="file">
      <b-upload
        v-model="file"
        type="file"
        accept=".csv"
        class="button is-primary"
        :native="true"
        @input="loadTextFromFile"
      >
        <b-icon icon="upload"></b-icon>
        <span>{{ buttonLabel }}</span>
      </b-upload>
    </b-field>
    <span v-if="file" style="margin-top: 0.2rem" class="file-name">
      {{ file.name }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Papa from 'papaparse';

export default defineComponent({
  name: 'CsvLoader',
  props: {
    storeModuleAction: {
      type: String,
      required: false,
      default: 'storeDataLoader/storeActivities',
    },
    writeToStore: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: 'Click to upload',
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    loadTextFromFile(ev) {
      this.$emit('loading', true);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const parseResult = Papa.parse(e.target.result, {
            skipEmptyLines: true,
          });
          const jsonData = this.convertToRealObject(parseResult.data);
          if (this.writeToStore) {
            this.$store.dispatch(this.storeModuleAction, jsonData);
          }
          const fields = Object.keys(jsonData[0]);
          this.$emit('fields', fields);
          this.$emit('file-error', '');
          this.$emit('json-data', jsonData);
        } catch (err) {
          console.error(err);
          this.$emit('file-error', err);
        } finally {
          this.$emit('loading', false);
        }
      };
      reader.readAsText(ev);
    },
    convertToRealObject(data) {
      const headerRow = data[0];
      const activityArray = [];
      for (let j = 1; j < data.length; j++) {
        const rowObj = {};
        for (let i = 0; i < data[j].length; i++) {
          rowObj[headerRow[i]] = data[j][i];
        }
        rowObj.links = rowObj.links ? JSON.parse(rowObj.links) : undefined;
        rowObj.duration = rowObj.duration
          ? parseInt(rowObj.duration)
          : undefined;
        rowObj.activityId = rowObj.activityId
          ? parseInt(rowObj.activityId)
          : undefined;
        rowObj.resourcePrefs = rowObj.resourcePrefs
          ? JSON.parse(rowObj.resourcePrefs)
          : undefined;
        activityArray.push(rowObj);
      }
      return activityArray;
    },
  },
});
</script>
