<template>
  <validation-observer class="container is-fluid">
    <div class="top-pad">
      <span @click="loadIntoHelp = !loadIntoHelp">
        <b-icon
          icon="question-circle"
          size="is-small"
          type="is-primary"
        ></b-icon>
      </span>
      <span class="bold">{{ $t('Choose your target environment') }}</span>
      <b-tooltip
        :label="
          $t(
            `Selects the target environment to load the activities into. Environments labelled 'Production' won't be shown`,
          )
        "
        multilined
        size="is-small"
        :active="loadIntoHelp"
        always
        position="is-bottom"
        type="is-dark"
      >
      </b-tooltip>
      <b-field>
        <b-dropdown v-model="loadEnvironment" hoverable>
          <b-button slot="trigger" icon-left="server" type="is-primary">
            Load into...
          </b-button>
          <b-dropdown-item
            v-for="option in targetEnvironments"
            :key="option.name"
            :value="option.name"
          >
            {{ option.friendlyName }}
          </b-dropdown-item>
        </b-dropdown>
        <b-input :value="friendlyTargetEnvName" disabled></b-input>
      </b-field>
    </div>

    <div class="top-pad">
      <span @click="apptPrefixHelp = !apptPrefixHelp">
        <b-icon
          icon="question-circle"
          size="is-small"
          type="is-primary"
        ></b-icon>
      </span>
      <span class="bold">{{ $t('Appt Number Prefix') }}</span>
      <b-tooltip
        :label="
          $t(
            `Specifying a value here and clicking the Apply Prefix button will prefix all the appointment numbers with the provided
                  value when the data is loaded.  If you want a separator you need to specify it.  For example, if the appt number is 012345
                  and you want BOB-012345, you would specify BOB- as the prefix.`,
          )
        "
        :active="apptPrefixHelp"
        always
        multilined
        size="is-small"
        position="is-bottom"
        type="is-dark"
      >
      </b-tooltip>
      <b-input v-model="apptPrefix" name="Appt Prefix" icon="text"></b-input>
    </div>

    <b-field class="top-pad">
      <b-button
        type="is-primary"
        icon-left="pencil-alt"
        @click="prefixApptNumbers"
      >
        {{ $t(`Apply prefix`) }}
      </b-button>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      name="Move Activities"
      rules="numeric"
    >
      <div class="top-pad">
        <span @click="moveActivityHelp = !moveActivityHelp">
          <b-icon
            icon="question-circle"
            size="is-small"
            type="is-primary"
          ></b-icon>
        </span>
        <span class="bold">{{ $t(`Move activities + / - (days)`) }}</span>
        <b-tooltip
          :label="
            $t(`Use this value to shift activities forward or backward in time.  Negative numbers shift the activities back in time,
            positive numbers forward in time.  You'll see the effect in the result table to the right`)
          "
          multilined
          size="is-small"
          :active="moveActivityHelp"
          always
          position="is-bottom"
          type="is-dark"
        >
        </b-tooltip>
        <b-input
          v-model="daysToShift"
          icon="arrows-alt-h"
          type="text"
        ></b-input>
      </div>
      <span class="help is-danger">
        {{ errors[0] }}
      </span>
    </validation-provider>
    <b-field style="padding-top: 5px">
      <b-button
        v-if="activities.length"
        icon-left="arrows-alt-h"
        type="is-primary"
        @click="shiftData()"
      >
        {{ dateLabel }}
      </b-button>
    </b-field>
    <span @click="resetSlaHelp = !resetSlaHelp">
      <b-icon icon="question-circle" size="is-small" type="is-primary"></b-icon>
    </span>
    <span class="bold">{{ $t(`Reset SLA or Shift SLA?`) }}</span>
    <b-tooltip
      :label="$t(`Reset or Shift`)"
      multilined
      size="is-small"
      :active="resetSlaHelp"
      always
      position="is-bottom"
      type="is-dark"
    >
    </b-tooltip>
    <br />
    <b-switch
      v-model="shiftSlaMode"
      true-value="Shift"
      false-value="Reset"
      style="padding-top: 5px"
    >
      {{ shiftSlaMode }}
    </b-switch>
    <validation-provider v-slot="{ errors }" name="Shift SLA" rules="numeric">
      <div v-if="shiftSlaMode === 'Shift' && activityCount > 0" class="top-pad">
        <span @click="shiftSlaHelp = !shiftSlaHelp">
          <b-icon
            icon="question-circle"
            size="is-small"
            type="is-primary"
          ></b-icon>
        </span>

        <span class="bold">{{ $t('Shift SLA in time') }}</span>
        <b-tooltip
          :label="
            $t(`Use this value to shift activity SLA forward or backward in time.  Negative numbers shift the activities back in time,
            positive numbers forward in time.  You can select whether you want to change the day, hour or minute value. You'll see the effect in the result table to the right`)
          "
          multilined
          size="is-small"
          :active="shiftSlaHelp"
          always
          position="is-bottom"
          type="is-dark"
        >
        </b-tooltip>
        <b-input
          v-model="slaAdjustment"
          icon="arrows-alt-h"
          type="text"
        ></b-input>
      </div>

      <span class="help is-danger">
        {{ errors[0] }}
      </span>
    </validation-provider>
    <div
      v-if="shiftSlaMode === 'Reset' && activityCount > 0"
      class="top-pad"
    ></div>
    <div
      v-if="shiftSlaMode === 'Shift' && activityCount > 0"
      class="bold"
      label="Units"
      style="padding-top: 5px"
    >
      <span class="bold">{{ $t('Units') }}</span>
      <b-select v-model="slaAdjustmentUnits">
        <option value="days">{{ $t('Days') }}</option>
        <option value="hours">{{ $t('Hours') }}</option>
        <option value="minutes">{{ $t('Minutes') }}</option>
      </b-select>
    </div>
    <div v-else-if="shiftSlaMode === 'Reset' && activityCount > 0">
      <b-field label="Reset SLA Start Date/Time to:">
        <b-datetimepicker
          v-model="slaStartTime"
          rounded
          placeholder="Click to select..."
          icon="calendar"
        >
        </b-datetimepicker>
      </b-field>
      <b-field label="Reset SLA End Date/Time to:">
        <b-datetimepicker
          v-model="slaEndTime"
          rounded
          placeholder="Click to select..."
          icon="calendar"
        >
        </b-datetimepicker>
      </b-field>
      <b-field v-if="shiftSlaMode === 'Reset'" style="padding-top: 5px">
        <b-button
          :disabled="!slaStartTime || !slaEndTime"
          type="is-primary"
          icon-left="arrows-alt-h"
          @click="resetSlaDate()"
        >
          {{ $t('Reset SLA') }}
        </b-button>
      </b-field>
    </div>
    <b-field v-if="shiftSlaMode === 'Shift'" style="padding-top: 5px">
      <b-button
        v-if="activities.length"
        :disabled="!slaAdjustment"
        type="is-primary"
        icon-left="arrows-alt-h"
        @click="shiftSlaDate()"
      >
        <span>{{ adjustmentLabel }}</span>
      </b-button>
    </b-field>
    <div class="top-pad">
      <span @click="removeDurationsHelp = !removeDurationsHelp">
        <b-icon
          icon="question-circle"
          size="is-small"
          type="is-primary"
        ></b-icon>
      </span>
      <span class="bold">{{ $t('Remove Durations on load?') }}</span>
      <b-tooltip
        :label="
          $t(`OFSC overwrites the expected duration with the actual duration when an activity is complete.
            Choosing Yes will remove the actual duration and force OFSC to recalculate the duration when loaded into the target.
            Choosing No will retain the actual duration from the source OFSC system.`)
        "
        multilined
        size="is-small"
        :active="removeDurationsHelp"
        always
        position="is-bottom"
        type="is-dark"
      >
      </b-tooltip>
      <b-field>
        <b-radio-button
          v-model="removeDurations"
          native-value="No"
          type="is-danger"
        >
          <b-icon icon="times"></b-icon>
          <span></span>
        </b-radio-button>
        <b-radio-button
          v-model="removeDurations"
          native-value="Yes"
          type="is-success"
        >
          <b-icon icon="check"></b-icon>
          <span></span>
        </b-radio-button>
      </b-field>
    </div>
    <b-button
      type="is-success"
      style="margin-top: 1em; margin-bottom: 2em"
      @click="onClose"
      >{{ $t('Save') }}</b-button
    >
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { required, numeric } from 'vee-validate/dist/rules';
import getFriendlyEnvName from '@/lib/getFriendlyEnvName';

setInteractionMode('eager');
extend('numeric', numeric);
extend('required', required);

export default defineComponent({
  name: 'ActivityLoadSidebar',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resetSlaHelp: false,
      apptPrefixHelp: false,
      shiftSlaHelp: false,
      loadIntoHelp: false,
      moveActivityHelp: false,
      removeDurationsHelp: false,
      shiftSlaMode: 'Shift',
      slaAdjustment: 0,
      slaAdjustmentUnits: 'days',
      slaStartTime: new Date(),
      apptPrefix: '',
      slaEndTime: undefined,
    };
  },
  computed: {
    ...mapGetters('storeDataLoader', ['activities', 'activityCount']),
    loadEnvironment: {
      get() {
        return this.$store.getters['storeDataLoader/loadEnvironment'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setLoadEnvironment', val);
      },
    },
    daysToShift: {
      get() {
        return this.$store.getters['storeDataLoader/daysToShift'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setDaysToShift', val);
      },
    },
    removeDurations: {
      get() {
        return this.$store.getters['storeDataLoader/removeDurations'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setRemoveDurations', val);
      },
    },
    friendlyTargetEnvName() {
      return getFriendlyEnvName(
        this.loadEnvironment,
        this.localEnvironmentList,
      );
    },
    localEnvironmentList() {
      let listIncludingFileLoaders = [];
      if (this.environmentList) {
        listIncludingFileLoaders = this.environmentList.map((env) => {
          return env;
        });
      }
      listIncludingFileLoaders.push({
        friendlyName: 'JSON',
        name: 'JSON',
        password: null,
        username: null,
        production: false,
      });
      listIncludingFileLoaders.push({
        friendlyName: 'CSV',
        name: 'CSV',
        password: null,
        username: null,
        production: false,
      });
      return listIncludingFileLoaders;
    },
    adjustmentLabel() {
      if (!this.slaAdjustment) {
        return this.$t('Shift SLA');
      } else {
        let label = `${this.$t(`Shift SLA by`)} ${this.slaAdjustment} `;
        label += this.pluralise(this.slaAdjustmentUnits, this.slaAdjustment);
        return label;
      }
    },
    dateLabel() {
      if (!this.daysToShift) {
        return this.$t('Shift date');
      } else {
        let label = `${this.$t('Shift date by')} ${this.daysToShift} `;
        label += this.pluralise(this.$t('days'), this.daysToShift);
        return label;
      }
    },
  },
  methods: {
    ...mapActions('storeDataLoader', ['storeActivities']),
    shiftData() {
      const activities = this.$copy(this.activities);
      //eslint-disable-next-line no-unused-vars
      for (let activity of activities) {
        if (activity.date) {
          const oldDate = this.$moment(activity.date, 'YYYY-MM-DD');
          const newDate = oldDate.add(this.daysToShift, 'days');
          activity.date = newDate.format('YYYY-MM-DD');
        }
      }
      this.storeActivities(activities);
    },
    prefixApptNumbers() {
      const activities = this.$copy(this.activities);
      activities.forEach((activity) => {
        if (activity.apptNumber) {
          activity.apptNumber = this.apptPrefix + activity.apptNumber;
        }
      });
      this.storeActivities(activities);
    },
    resetSlaDate() {
      const activities = this.$copy(this.activities);
      const dateFormat = 'YYYY-MM-DD HH:mm:ss';
      for (let activity of activities) {
        if (activity.slaWindowStart) {
          const newSlaStart = this.$moment(this.slaStartTime);
          activity.slaWindowStart = newSlaStart.format(dateFormat);
          // Only apply the SLA End if it's been specified (we'll always have a
          // specified start as it defaults to 'now')
          if (this.slaEndTime) {
            const newSlaEnd = this.$moment(this.slaEndTime);
            activity.slaWindowEnd = newSlaEnd.format(dateFormat);
          }
        }
      }
      this.storeActivities(activities);
    },
    shiftSlaDate() {
      const activities = this.$copy(this.activities);
      const dateFormat = 'YYYY-MM-DD HH:mm:ss';
      //eslint-disable-next-line no-unused-vars
      for (let activity of activities) {
        if (activity.slaWindowStart) {
          const oldSlaStart = this.$moment(activity.slaWindowStart, dateFormat);
          const newSlaStartDate = oldSlaStart.add(
            this.slaAdjustment,
            this.slaAdjustmentUnits,
          );
          activity.slaWindowStart = newSlaStartDate.format(dateFormat);
        }
        if (activity.slaWindowEnd) {
          const oldSlaEnd = this.$moment(activity.slaWindowEnd, dateFormat);
          const newSlaEndDate = oldSlaEnd.add(
            this.slaAdjustment,
            this.slaAdjustmentUnits,
          );
          activity.slaWindowEnd = newSlaEndDate.format(dateFormat);
        }
      }
      this.storeActivities(activities);
    },
    onClose() {
      this.$emit('close', false);
    },
  },
});
</script>

<style></style>
