<!--
  Component that allows activity extraction and load with OFSC
-->
<template>
  <div class="container is-fluid" style="padding-top: 1rem">
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <div class="flex-parent mb1">
      <b-button
        icon-left="arrow-to-left"
        icon-pack="fad"
        type="is-primary"
        @click="sidebarIsOpen = true"
      >
        {{ $t('Extract Criteria') }}
      </b-button>
      <b-button
        :disabled="!invalid || !extractEnvironment"
        type="is-success"
        icon-left="download"
        icon-pack="fad"
        @click="retrieveData()"
      >
        {{ $t('Extract Activities') }}
      </b-button>
      <b-button
        type="is-primary"
        icon-pack="fad"
        icon-left="lightbulb"
        @click="showWhatsNew()"
      >
        What's New?
      </b-button>
      <b-button
        :disabled="!invalid || !loadEnvironment"
        type="is-success"
        icon-right="upload"
        icon-pack="fad"
        @click="confirmLoad()"
      >
        {{ $t('Load Activities') }}
      </b-button>

      <b-button
        :disabled="activityCount === 0"
        icon-right="arrow-to-right"
        icon-pack="fad"
        type="is-primary"
        @click="loadSidebarIsOpen = true"
        >{{ $t('Load Criteria') }}</b-button
      >
    </div>
    <div>
      <b-sidebar
        :open.sync="sidebarIsOpen"
        type="is-white"
        :fullheight="true"
        :fullwidth="false"
        :overlay="false"
        :right="false"
        v:on="$listeners"
      >
        <activity-migrator-criteria-sidebar
          @file-error="handleFileError"
          @export-csv-template="handleExportTemplate"
        />
      </b-sidebar>
      <b-sidebar
        :open.sync="loadSidebarIsOpen"
        type="is-white"
        :fullheight="true"
        :fullwidth="false"
        :overlay="false"
        :right="true"
        v:on="$listeners"
      >
        <activity-load-sidebar @close="loadSidebarIsOpen = false" />
      </b-sidebar>
      <b-table
        :checked-rows.sync="checkedRows"
        :data="activities"
        :columns="tableColumns"
        :paginated="true"
        :per-page="rowsPerPage"
        style="overflow-x: scroll"
        checkable
        striped
        narrowed
        sticky-header
        detailed
        :height="tableHeight"
        detail-key="activityId"
      >
        <template slot="bottom-left">
          <span v-if="fileError" class="help is-danger">{{ fileError }}</span>
          <b>Total checked</b>: {{ checkedRows.length }}
          <b-field style="margin-left: 5em">
            <b-dropdown v-model="rowsPerPage" hoverable position="is-top-right">
              <b-button slot="trigger" type="is-primary">
                {{ $t('Activities per page...') }}
              </b-button>
              <b-dropdown-item value="25"> 25 </b-dropdown-item>
              <b-dropdown-item value="50"> 50 </b-dropdown-item>
              <b-dropdown-item value="100"> 100 </b-dropdown-item>
            </b-dropdown>
            <b-input
              style="width: 4rem"
              :value="rowsPerPage"
              :disabled="true"
            />
          </b-field>
        </template>
        <template slot="top-left">
          {{ $t('Type in the heading to filter results') }}
        </template>
        <template #detail="props">
          <div
            v-if="props.row.resourcePrefs && props.row.resourcePrefs.length > 0"
            style="padding-bottom: 1em"
          >
            <p class="has-text-weight-semibold has-text-primary">
              Resource Preferences
            </p>
            <table style="width: 10em">
              <th>Resource Id</th>
              <th>Resource Internal Id</th>
              <th>Preference Type</th>
              <tr v-for="(item, index) in props.row.resourcePrefs" :key="index">
                <td>{{ item.resourceId }}</td>
                <td>{{ item.resourceInternalId }}</td>
                <td>{{ item.preferenceType }}</td>
              </tr>
            </table>
          </div>
          <div v-else style="padding-bottom: 1em">
            <p class="has-text-weight-semibold has-text-primary">
              No Resource Preferences
            </p>
          </div>
          <div v-if="props.row.links && props.row.links.length > 0">
            <p class="has-text-weight-semibold has-text-primary">
              Activity Links
            </p>
            <table style="width: 10em">
              <th>From Activity</th>
              <th>To Activity</th>
              <th>Link Type</th>
              <tr v-for="(item, index) in props.row.links" :key="index">
                <td>{{ item.fromActivityId }}</td>
                <td>{{ item.toActivityId }}</td>
                <td>{{ item.linkType }}</td>
              </tr>
            </table>
          </div>
          <div v-else style="padding-bottom: 1em">
            <p class="has-text-weight-semibold has-text-primary">
              No Activity Links
            </p>
          </div>
        </template>
      </b-table>
      <p>{{ $t('Activities retrieved: ') + activityCount }}</p>
      <div class="buttons">
        <b-button
          :disabled="activityCount === 0"
          type="is-primary"
          style="width: 180px"
          icon-left="download"
          icon-pack="fad"
          @click="exportJson"
        >
          {{ $t('Export to JSON') }}
        </b-button>
        <b-button
          :disabled="activityCount === 0"
          type="is-primary"
          icon-left="download"
          icon-pack="fad"
          style="width: 180px"
          @click="exportCsv"
        >
          {{ $t('Export to CSV') }}
        </b-button>
        <b-button
          v-if="activityCount > 0"
          :disabled="!checkedRows.length"
          type="is-danger"
          icon-left="times-circle"
          @click="deleteChecked()"
        >
          {{ $t('Delete checked rows') }}
        </b-button>
        <b-button
          v-if="activityCount > 0"
          type="is-danger"
          :disabled="!checkedRows.length"
          icon-left="undo"
          @click="checkedRows = []"
        >
          {{ $t('Clear checked') }}
        </b-button>
        <b-button
          v-if="activityCount > 0"
          :disabled="activityCount === 0"
          type="is-danger"
          icon-left="trash"
          @click="discardActivities()"
        >
          {{ $t('Discard Results') }}
        </b-button>
      </div>
      <br />
      <b-button
        v-if="loadResults.length > 0"
        type="is-primary"
        icon-left="eye"
        @click="showResults"
      >
        {{ $t('Display Results') }}
      </b-button>
      <div class="buttons"></div>
      <strong>
        <p v-if="authError" class="has-text-danger">
          {{ authError }}.
          <br />
          ${{ $t('Please try logging out and back in') }}
        </p>
      </strong>
      <strong>
        <p v-if="error" class="has-text-danger">
          {{ error }}
        </p>
      </strong>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import NProgress from 'nprogress';

import saveFile from 'save-as-file';

import ActivityLoadResultModal from './ActivityLoadResultModal';
import {
  retrieveActivities,
  loadActivities,
} from '@/services/activityMigratorService.js';

import getFileName from '@/lib/getFileName';
import { exportCsv } from '@/lib/exportData';
import ActivityMigratorCriteriaSidebar from './ActivityMigratorCriteriaSidebar';
import ActivityLoadSidebar from './ActivityLoadSidebar';
import ActivityMigratorWhatsNew from './ActivityMigratorWhatsNew';

export default defineComponent({
  name: 'ActivityMigratorMain',
  components: {
    ActivityMigratorCriteriaSidebar,
    ActivityLoadSidebar,
  },
  data() {
    return {
      identifyBy: 'apptNumber',
      showModal: false,
      retrieveIsInFlight: false,
      authError: null,
      error: null,
      loadResult: null,
      isLoading: false,
      checkedRows: [],
      rowsPerPage: 25,
      fileError: null,
      tableColumns: [],
      loadSidebarIsOpen: false,
    };
  },
  computed: {
    ...mapGetters('storeDataLoader', [
      'activities',
      'activityCount',
      'loadResults',
      'activityFields',
      'includeChildren',
      'removeCancelled',
      'removeNonCustomer',
      'attemptUpdate',
      'extractEnvironment',
      'loadEnvironment',
      'daysToShift',
      'backToBucket',
      'activityLimit',
      'dates',
      'resourceParent',
      'shouldRetrieveActivityLinks',
      'shouldRetrieveResourcePrefs',
      'includeNonScheduled',
      'nonCustomerActivityTypes',
      'invalid',
    ]),
    tableHeight() {
      return document.documentElement.clientHeight * 0.65;
    },
    dateFrom() {
      const momDate = this.$moment(
        this.$store.getters['storeDataLoader/dateFrom'],
      );
      return momDate.format('YYYY-MM-DD');
    },
    dateTo() {
      const momDate = this.$moment(
        this.$store.getters['storeDataLoader/dateTo'],
      );
      return momDate.format('YYYY-MM-DD');
    },
    sidebarIsOpen: {
      get() {
        return this.$store.getters['storeDataLoader/sidebarOpen'];
      },
      set(isOpen) {
        this.$store.dispatch('storeDataLoader/setSidebarOpen', isOpen);
      },
    },
  },
  watch: {
    activityFields: {
      immediate: true,
      handler: function (newValue) {
        const cols = [];
        if (!newValue) return;
        for (let i = 0; i < newValue.length; i++) {
          if (newValue[i]) {
            cols.push({
              sortable: true,
              searchable: true,
              field: newValue[i].label,
              label: newValue[i].title,
            });
          }
        }
        this.tableColumns = cols;
      },
    },
  },
  beforeDestroy() {
    this.$off('close');
  },
  created() {
    this.fetchEnvironments(false);
  },
  methods: {
    ...mapActions('storeDataLoader', ['discardActivities', 'storeLoadResults']),
    showWhatsNew() {
      this.$buefy.modal.open({
        parent: this,
        component: ActivityMigratorWhatsNew,
        hasModalCard: false,
        width: 960,
      });
    },
    showResults() {
      this.$buefy.modal.open({
        parent: this,
        component: ActivityLoadResultModal,
        hasModalCard: false,
        width: '98vw',
      });
    },
    handleFileError(evt) {
      this.fileError = evt;
    },
    handleExportTemplate(evt) {
      exportCsv(evt, null, 'csv', this.tableColumns, []);
    },
    selectAll(rows) {
      if (rows.length > this.checkedRows.length) {
        this.activities.filter((item) =>
          this.checkedRows.every(
            (item2) => item2.activityId !== item.activityId,
          ),
        );
        this.$nextTick(() => {
          this.checkedRows = this.data;
        });
      } else {
        this.$nextTick(() => {
          this.checkedRows = [];
        });
      }
    },
    deleteChecked() {
      // Remove the checked rows from the activities array
      this.$store.dispatch(
        'storeDataLoader/storeActivities',
        this.activities.filter((item) =>
          this.checkedRows.every(
            (item2) => item2.activityId !== item.activityId,
          ),
        ),
      );
    },
    getArrayOfFieldLabels() {
      const fieldLabelArray = this.activityFields.map((field) => {
        if (field) return field.label;
      });
      return fieldLabelArray;
    },
    async retrieveData() {
      this.error = null;
      if (!this.retrieveIsInFlight) {
        this.retrieveIsInFlight = true;
        this.$store.dispatch('storeDataLoader/discardLoadResults');

        this.isLoading = true;

        try {
          const fieldLabelArray = this.getArrayOfFieldLabels();
          const fields = this.convertArrayToCSV(fieldLabelArray);

          const result = await retrieveActivities({
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            moveToBucket: this.backToBucket,
            extractEnvironment: this.extractEnvironment,
            customer: this.$configData.customer.name,
            resources: this.resourceParent,
            includeChildren: this.includeChildren,
            fields: fields,
            removeNonCustomer: this.removeNonCustomer,
            internalGroups: this.$configData.activityMigrator.internalGroups,
            includeNonScheduled: this.includeNonScheduled,
            includeResourcePrefs: this.shouldRetrieveResourcePrefs,
            includeActivityLinks: this.shouldRetrieveActivityLinks,
            nonCustomerActivityTypes: this.convertArrayToCSV(
              this.nonCustomerActivityTypes,
            ),
            removeCancelled: this.removeCancelled,
            limit: this.activityLimit,
            userId: this.userId,
          });

          if (result) {
            this.$store.dispatch(
              'storeDataLoader/storeActivities',
              result.data,
            );
          } else {
            this.error = 'No activities were retrieved';
          }
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.error = `${JSON.stringify(error.response.data)} : ${
              error.response.status
            }`;
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            this.error = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            this.error = error.message;
          }
        } finally {
          this.retrieveIsInFlight = false;
          this.isLoading = false;
          // NProgress.done();
        }
      }
    },
    exportCsv() {
      exportCsv(
        'activities',
        this.resourceParent,
        'csv',
        this.tableColumns,
        this.activities,
        true,
      );
    },
    exportJson() {
      const json = JSON.stringify(this.activities);
      const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
      saveFile(
        blob,
        getFileName('activities', this.resourceParent, 'json', this.activities),
      );
    },
    async confirmLoad() {
      this.loadResult = null;
      this.$buefy.dialog.confirm({
        message: `Are you sure you wish to load data into ${this.loadEnvironment}?`,
        onConfirm: async () => {
          this.error = null;
          this.$buefy.toast.open('Starting data load...');

          try {
            NProgress.start();
            const dataKeys = Object.keys(this.activities[0]);
            const columns = this.tableColumns.map((column) => {
              return column.field;
            });
            const fieldsToDelete = dataKeys.filter(
              (key) => !columns.includes(key),
            );
            const data = this.activities.map((activity) => {
              const newActivity = this.$copy(activity);
              fieldsToDelete.forEach((key) => {
                if (key === 'links' || key === 'resourcePrefs') return;
                delete newActivity[key];
              });
              return newActivity;
            });
            const loadResult = await loadActivities({
              loadEnvironment: this.loadEnvironment,
              customer: this.$configData.customer.name,
              removeDurations: this.removeDurations,
              userId: this.userId,
              attemptUpdate: this.attemptUpdate,
              identifyBy: this.identifyBy,
              data: data,
            });

            this.$buefy.toast.open('Load complete');
            await this.storeLoadResults(loadResult.data);
            this.showResults();
          } catch (loadError) {
            console.log(loadError);
            this.error = `Failed to load activities: ${JSON.stringify(
              loadError,
            )}`;
          } finally {
            NProgress.done();
          }
        },
      });
    },
  },
});
</script>

<style scoped lang="scss">
.flex-parent {
  display: flex;
  justify-content: space-between;
}

.mb1 {
  margin-bottom: 1em;
}

span.bold {
  font-weight: 700;
}
</style>
