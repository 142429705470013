<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Activity Load Results') }}
      </p>
    </header>
    <section class="modal-card-body">
      <div class="block">
        <b-radio v-model="filterRecordsBy" name="all" native-value="all">
          Show All
        </b-radio>
        <b-radio
          v-model="filterRecordsBy"
          name="successful"
          native-value="successful"
        >
          Successful
        </b-radio>
        <b-radio v-model="filterRecordsBy" name="failed" native-value="failed">
          Failed
        </b-radio>
      </div>
      <b-table
        :data="filteredData"
        detailed
        paginated
        per-page="10"
        sticky-header
        style="height: 500px"
      >
        <b-table-column
          v-slot="props"
          field="activityId"
          :label="$t('Activity ID')"
          sortable
          numeric
        >
          <template slot="subheading"> Total </template>
          {{ getActivityId(props.row) }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="apptNumber"
          label="Appt. No."
          sortable
          >{{ getApptNumber(props.row) }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="customerNumber"
          :label="$t('Cust. No.')"
          sortable
          >{{ props.row.customerNumber }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="operationsPerformed"
          :label="$t('Successful Operations')"
          sortable
        >
          <template slot="subheading">
            {{ numberSuccessful }}
          </template>
          {{ props.row.operationsPerformed }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="operationsFailed"
          :label="$t('Failed Operations')"
          sortable
        >
          <template slot="subheading">
            {{ numberFailed }}
          </template>
          {{ props.row.operationsFailed }}</b-table-column
        >
        <template slot="detail" slot-scope="props">
          <article>
            <div>
              <div class="content">
                <p v-if="props.row.error">
                  <strong>{{ 'Error details' }}</strong>
                  <br />
                  <strong
                    >{{ $t('Activity ID') }}:
                    {{ getActivityId(props.row) }}</strong
                  >
                  <br />
                  <strong
                    >{{ $t('Appt Number') }}:
                    {{ getApptNumber(props.row) }}</strong
                  >
                  <br />
                  <span>
                    {{ props.row.error }}
                    <br />{{ $t('Supplied Data') }}:
                    <br />
                    {{ props.row.originalData }}
                  </span>
                </p>
                <p v-else>{{ $t('No errors') }}</p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
      <div class="buttons">
        <b-button type="is-success" @click="createCsv()">
          {{ $t('Export to CSV') }}
        </b-button>
        <b-button type="is-primary" @click="$emit('close')">
          {{ $t('Close') }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { exportCsv } from '@/lib/exportData';

export default defineComponent({
  name: 'ActivityLoadResultModal',
  data() {
    return {
      filterRecordsBy: 'all',
    };
  },
  computed: {
    ...mapGetters('storeDataLoader', ['loadResults']),
    headings() {
      return [
        'activityId',
        'apptNumber',
        'customerNumber',
        'operationsPerformed',
        'operationsFailed',
        'error',
      ];
    },
    filteredData() {
      return this.loadResults.filter((item) => {
        if (this.filterRecordsBy === 'all') {
          return true;
        }
        if (this.filterRecordsBy === 'failed') {
          return item.operationsFailed;
        }
        if (this.filterRecordsBy === 'successful') {
          return item.operationsPerformed;
        }
      });
    },
    numberSuccessful() {
      return this.loadResults.reduce((total, item) => {
        return item.operationsPerformed ? total + 1 : total;
      }, 0);
    },
    numberFailed() {
      return this.loadResults.reduce((total, item) => {
        return item.operationsFailed ? total + 1 : total;
      }, 0);
    },
  },
  methods: {
    createCsv() {
      exportCsv('results', null, 'csv', this.headings, this.loadResults);
    },
    getActivityId(row) {
      if (row.activityId) {
        return row.activityId;
      }
      if (row?.originalData?.activityId) {
        return row.originalData?.activityId;
      }
      return '';
    },
    getApptNumber(row) {
      if (row.apptNumber) {
        return row.apptNumber;
      }
      if (row?.originalData?.apptNumber) {
        return row.originalData?.apptNumber;
      }
      return '';
    },
    getCustomerNumber(row) {
      if (row.customerNumber) {
        return row.customerNumber;
      }
      if (row?.originalData?.customerNumber) {
        return row.originalData?.customerNumber;
      }
      return '';
    },
  },
});
</script>
