import axios from 'axios';
import configData from '../config/config.json';
import moment from 'moment';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.activityMigrator.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export const retrieveActivityTypes = ({
  extractEnvironment,
  customer,
  groups,
  userId,
}) => {
  let params = new URLSearchParams();
  params.append('env', extractEnvironment);
  params.append('customer', customer);
  params.append('internalGroups', groups);
  params.append('userId', userId);

  const options = {
    method: 'GET',
    params,
    url: configData.activityMigrator.activityTypeUrl,
  };
  return apiClient(options);
};

export const retrieveActivities = async ({
  dateFrom,
  dateTo,
  moveToBucket,
  extractEnvironment,
  customer,
  resources,
  includeChildren,
  fields,
  removeNonCustomer,
  includeResourcePrefs,
  includeActivityLinks,
  internalGroups,
  includeNonScheduled,
  nonCustomerActivityTypes,
  removeCancelled,
  limit,
  userId,
}) => {
  let params = new URLSearchParams();
  params.append('dateFrom', moment(dateFrom).format('YYYY-MM-DD'));
  params.append('dateTo', moment(dateTo).format('YYYY-MM-DD'));
  params.append('moveToBucket', moveToBucket);
  params.append('env', extractEnvironment);
  params.append('customer', customer);
  params.append('resources', resources);
  params.append('includeChildren', includeChildren);
  params.append('fields', fields);
  params.append('removeNonCustomer', removeNonCustomer);
  params.append('internalGroups', internalGroups);
  params.append('includeNonScheduled', includeNonScheduled);
  params.append('includeResourcePrefs', includeResourcePrefs);
  params.append('includeActivityLinks', includeActivityLinks);
  if (removeNonCustomer === 'Yes') {
    params.append('activityTypesToRemove', nonCustomerActivityTypes);
  }
  params.append('removeCancelled', removeCancelled);
  params.append('limit', limit);

  apiClient.defaults.headers.userId = userId;
  const options = {
    method: 'GET',
    url: configData.activityMigrator.activityRetrievalUrl,
    params,
  };
  return apiClient(options);
};

export const loadActivities = async ({
  loadEnvironment,
  customer,
  removeDurations,
  userId,
  attemptUpdate,
  identifyBy,
  data,
}) => {
  let params = new URLSearchParams();
  params.append('env', loadEnvironment);
  params.append('customer', customer);
  params.append('removeDurations', removeDurations);
  params.append('userId', userId);
  params.append('attemptUpdate', attemptUpdate);
  if (attemptUpdate === 'Yes') {
    params.append('identifyBy', identifyBy);
  }
  apiClient.defaults.headers.userId = userId;
  const options = {
    url: configData.activityMigrator.activityUrl,
    method: 'POST',
    data,
    params,
  };
  return apiClient(options);
};

export const getProperties = async ({ env, customer, userId }) => {
  const params = new URLSearchParams();
  params.append('env', env);
  params.append('customer', customer);
  params.append('userId', userId);
  apiClient.defaults.headers.userId = userId;
  const options = {
    url: configData.activityMigrator.propertyRetrievalUrl,
    method: 'get',
    params,
  };
  const { data } = await apiClient(options);
  return data;
};
