<!-- eslint-disable max-len -->
<template>
  <validation-observer ref="valObs" class="mt-1 container is-fluid">
    <div>
      <span @click="extractEnvHelp = !extractEnvHelp">
        <b-icon
          icon="question-circle"
          size="is-small"
          type="is-primary"
        ></b-icon>
      </span>
      <span class="ml-0_25 has-text-weight-semibold">{{
        $t('Where is your source data?')
      }}</span>
      <br />
      <b-tooltip
        :label="
          $t(`This is the environment you'd like to extract activities from`)
        "
        :active="extractEnvHelp"
        always
        position="is-bottom"
        type="is-dark"
        multilined
        size="is-small"
        ><b-field>
          <b-dropdown v-model="extractEnvironment" hoverable>
            <b-button slot="trigger" icon-left="server" type="is-primary">
              Extract from...
            </b-button>
            <b-dropdown-item
              v-for="option in localEnvironmentList"
              :key="option.name"
              :value="option.name"
            >
              {{ option.friendlyName }}
            </b-dropdown-item>
          </b-dropdown>
          <b-input :value="friendlySourceEnvName" disabled></b-input>
        </b-field>
      </b-tooltip>

      <json-loader
        v-if="extractEnvironment === 'JSON'"
        @file-error="recordfileError"
      />
      <div v-if="extractEnvironment === 'CSV'" class="top-pad">
        <csv-loader @file-error="recordfileError" @fields="handleCsvFields" />
        <div class="top-pad">
          <b-button class="is-success" @click="createCsvTemplate()">{{
            $t('Download CSV Template')
          }}</b-button>
        </div>
      </div>

      <validation-provider
        v-slot="{ errors }"
        rules="required|maxDateRange"
        name="Date Range"
      >
        <div v-if="!hideInputs" class="top-pad">
          <span @click="dateRangeHelp = !dateRangeHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">{{
            $t('Activity date range')
          }}</span>
          <b-tooltip
            :label="
              $t(
                `Select the start and end range for the activities you'd like in the extract (inclusive)`,
              )
            "
            :active="dateRangeHelp"
            always
            multilined
            size="is-small"
            position="is-bottom"
            type="is-dark"
          ></b-tooltip>
          <b-datepicker
            ref="Dates"
            v-model="dates"
            name="Dates"
            :placeholder="$t('Click to select...')"
            icon="calendar"
            range
            :first-day-of-week="1"
          ></b-datepicker>
        </div>
        <span class="help is-danger">
          {{ errors[0] }}
        </span>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Parent resource"
        rules="required"
      >
        <div v-if="!hideInputs" class="top-pad">
          <span @click="parentResourceHelp = !parentResourceHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>

          <span class="ml-0_25 has-text-weight-semibold">{{
            $t('Parent resource')
          }}</span>
          <b-tooltip
            :label="
              $t(
                `This is the resource ID of the resource at the highest point in the OFSC organisation tree, usually a bucket, below which, you'd like to extract all activities`,
              )
            "
            :active="parentResourceHelp"
            always
            multilined
            size="is-small"
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-input
            v-model="resourceParent"
            icon="sitemap"
            type="is-dark"
          ></b-input>
        </div>
        <span class="help is-danger">
          {{ errors[0] }}
        </span>
      </validation-provider>
      <span v-if="!hideInputs">
        <div class="top-pad">
          <span @click="includeChildrenHelp = !includeChildrenHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t(`Include resource children?`) }}
          </span>
          <b-tooltip
            :label="
              $t(
                `Set to All to include all activities in all child buckets.  Set to Immediate to include activities in the specified bucket and it's first level descendant (e.g. a bucket and it's directly assigned engineers. Set to None to include only activities in the specified bucket`,
              )
            "
            multilined
            size="is-small"
            :active="includeChildrenHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button v-model="includeChildren" native-value="none">
              None
            </b-radio-button>
            <b-radio-button v-model="includeChildren" native-value="immediate">
              Immediate
            </b-radio-button>
            <b-radio-button v-model="includeChildren" native-value="all">
              All
            </b-radio-button>
          </b-field>
        </div>

        <div class="top-pad">
          <span @click="fieldsHelp = !fieldsHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">{{
            $t(`Fields to extract`)
          }}</span>
          <b-tooltip
            :label="$t(`Field extract help`)"
            :active="fieldsHelp"
            always
            multilined
            size="is-small"
            position="is-bottom"
            type="is-dark"
          ></b-tooltip>
          <b-field>
            <b-button
              type="is-primary"
              :disabled="!(extractEnvironment && extractEnvironment !== 'file')"
              @click="showPropSelector"
            >
              {{ $t('Select Properties') }}
            </b-button>
          </b-field>
        </div>

        <div class="top-pad">
          <span @click="includeNonScheduledHelp = !includeNonScheduledHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t(`Include non-scheduled activities?`) }}
          </span>
          <b-tooltip
            :label="
              $t(
                `Activities without a date are classed as non-scheduled.  Set this to Yes to include these activities in the extract`,
              )
            "
            multilined
            size="is-small"
            :active="includeNonScheduledHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button
              v-model="includeNonScheduled"
              native-value="No"
              type="is-danger"
            >
              <b-icon icon="times"></b-icon>
              <span></span>
            </b-radio-button>
            <b-radio-button
              v-model="includeNonScheduled"
              native-value="Yes"
              type="is-success"
            >
              <b-icon icon="check"></b-icon>
              <span></span>
            </b-radio-button>
          </b-field>
        </div>

        <div class="top-pad">
          <span @click="includeActivityLinkHelp = !includeActivityLinkHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t(`Include Linked Activities?`) }}
          </span>
          <b-tooltip
            :label="
              $t(
                `For each extracted activity an API call must be made to retrieve any linked activities, regardless of whether there are activities to link to.
                  This is unfortunately very slow and rate limited by OFSC.  By turning this setting off you can retrieve activities without links and have a much faster extract experience`,
              )
            "
            multilined
            size="is-small"
            :active="includeActivityLinkHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button
              v-model="shouldRetrieveActivityLinks"
              native-value="No"
              type="is-danger"
            >
              <b-icon icon="times"></b-icon>
              <span></span>
            </b-radio-button>
            <b-radio-button
              v-model="shouldRetrieveActivityLinks"
              native-value="Yes"
              type="is-success"
            >
              <b-icon icon="check"></b-icon>
              <span></span>
            </b-radio-button>
          </b-field>
        </div>

        <div class="top-pad">
          <span @click="includeResourcePrefHelp = !includeResourcePrefHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t(`Include Resource Preferences?`) }}
          </span>
          <b-tooltip
            :label="
              $t(
                `For each extracted activity an API call must be made to retrieve any resource preferences activities, regardless of whether there are preferences for that activity as there
                  is no way to identify which ones do and don't upfront.
                  This is unfortunately very slow and rate limited by OFSC.  By turning this setting off you can retrieve activities without trying to retrieve resource preferences and have a much faster extract experience`,
              )
            "
            multilined
            size="is-small"
            :active="includeResourcePrefHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button
              v-model="shouldRetrieveResourcePrefs"
              native-value="No"
              type="is-danger"
            >
              <b-icon icon="times"></b-icon>
              <span></span>
            </b-radio-button>
            <b-radio-button
              v-model="shouldRetrieveResourcePrefs"
              native-value="Yes"
              type="is-success"
            >
              <b-icon icon="check"></b-icon>
              <span></span>
            </b-radio-button>
          </b-field>
        </div>

        <div class="top-pad">
          <span @click="parentBucketHelp = !parentBucketHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold"
            >Move into parent bucket?</span
          >
          <b-tooltip
            :label="
              $t(`Each activity in the extract system is likely to have been assigned to, and processed by, an engineer.
            Selecting Yes will move the activity into the assigned engineer's parent bucket so that it can be routed in the target system.
            Selecting No will retain the resource assignment from the source OFSC system unless it is an internal activity in which case it
            will remain with the assigned resource`)
            "
            multilined
            size="is-small"
            :active="parentBucketHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button
              v-model="backToBucket"
              native-value="No"
              type="is-danger"
            >
              <b-icon icon="times"></b-icon>
              <span></span>
            </b-radio-button>
            <b-radio-button
              v-model="backToBucket"
              native-value="Yes"
              type="is-success"
            >
              <b-icon icon="check"></b-icon>
              <span></span>
            </b-radio-button>
          </b-field>
        </div>

        <b-field class="top-pad">
          <b-button
            :disabled="!(extractEnvironment && extractEnvironment !== 'file')"
            type="is-primary"
            icon-left="pencil-alt"
            @click="retrieveActivityTypes"
          >
            <span>{{ $t(`List internal activity types`) }}</span>
          </b-button>
        </b-field>
      </span>
      <div v-show="nonCustomerActivityTypes.length > 0">
        <div
          v-show="extractEnvironment !== 'file' && removeNonCustomer === 'Yes'"
        >
          <span @click="nonCustomerActivitiesHelp = !nonCustomerActivitiesHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
              class="mt-1"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t('Non-customer activity types') }}
          </span>
          <b-tooltip
            :label="
              $t(`This is a list of non-customer activity labels which
            will be ignored if the Remove non-customer activities option is selected. Click
            the button below to retrieve the list and you can add or remove as necessary`)
            "
            :active="nonCustomerActivitiesHelp"
            always
            multilined
            size="is-small"
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-taginput
              v-model="nonCustomerActivityTypes"
              class="scroll-tags"
              type="is-primary"
              rounded
              size="is-medium"
              ellipsis
              @remove="handleTagRemove"
            ></b-taginput>
          </b-field>
        </div>
        <div v-if="extractEnvironment !== 'file'" class="top-pad">
          <span @click="removeNonCustomerHelp = !removeNonCustomerHelp">
            <b-icon
              icon="question-circle"
              size="is-small"
              type="is-primary"
            ></b-icon>
          </span>
          <span class="ml-0_25 has-text-weight-semibold">{{
            $t(`Remove non-customer activities?`)
          }}</span>
          <b-tooltip
            :label="
              $t(`Removes non-customer facing activities from the extract.  If set to No, the loader will not move the internal activities to the bucket
                   as most types cannot be assigned to a bucket and must remain with an engineer`)
            "
            multilined
            size="is-small"
            :active="removeNonCustomerHelp"
            always
            position="is-bottom"
            type="is-dark"
          >
          </b-tooltip>
          <b-field>
            <b-radio-button
              v-model="removeNonCustomer"
              native-value="No"
              type="is-danger"
            >
              <b-icon icon="times"></b-icon>
              <span></span>
            </b-radio-button>
            <b-radio-button
              v-model="removeNonCustomer"
              native-value="Yes"
              type="is-success"
            >
              <b-icon icon="check"></b-icon>
              <span></span>
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div v-if="!hideInputs" class="top-pad">
        <span @click="removeCancelledHelp = !removeCancelledHelp">
          <b-icon
            icon="question-circle"
            size="is-small"
            type="is-primary"
          ></b-icon>
        </span>
        <span class="ml-0_25 has-text-weight-semibold">{{
          $t('Remove cancelled activities?')
        }}</span>
        <b-tooltip
          :label="
            $t('Removes activities in the Cancelled status from the extract')
          "
          multilined
          size="is-small"
          :active="removeCancelledHelp"
          always
          position="is-bottom"
          type="is-dark"
        >
        </b-tooltip>
        <b-field>
          <b-radio-button
            v-model="removeCancelled"
            native-value="No"
            type="is-danger"
          >
            <b-icon icon="times"></b-icon>
            <span></span>
          </b-radio-button>
          <b-radio-button
            v-model="removeCancelled"
            native-value="Yes"
            type="is-success"
          >
            <b-icon icon="check"></b-icon>
            <span></span>
          </b-radio-button>
        </b-field>
      </div>
      <validation-provider
        v-slot="{ errors }"
        rules="max_value:100000"
        name="Activity Limit"
      >
        <div v-if="!hideInputs" class="top-pad">
          <span class="ml-0_25 has-text-weight-semibold">
            {{ $t('Max. number of activities to retrieve') }}
          </span>
          <b-field>
            <b-input v-model="activityLimit" type="text"></b-input>
          </b-field>
          <span class="help is-danger">
            {{ errors[0] }}
          </span>
        </div>
      </validation-provider>
    </div>
    <b-button type="is-success" style="margin-bottom: 2em" @click="onClose">{{
      $t('Save')
    }}</b-button>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { required, max_value, numeric } from 'vee-validate/dist/rules';

import { retrieveActivityTypes } from '@/services/activityMigratorService.js';
import JSONLoader from '../JsonLoader';
import CSVLoader from '../CsvLoader';
import configData from '@/config/config.json';
import PropSelector from './PropSelector.vue';
import getFriendlyEnvName from '@/lib/getFriendlyEnvName';

setInteractionMode('eager');
extend('max_value', max_value);
extend('numeric', numeric);
extend('required', required);

extend('maxDateRange', {
  params: ['target'],
  message: () => {
    return 'Dates must be less than 30 days apart';
  },
  validate: (value) => {
    const dateFrom = moment(value[0]);
    const dateTo = moment(value[1]);
    const diff = dateTo.diff(dateFrom, 'days');
    return diff <= 30;
  },
});

export default defineComponent({
  name: 'ActivityMigratorCriteriaSidebar',
  components: {
    jsonLoader: JSONLoader,
    csvLoader: CSVLoader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      environments: [],
      includeNonScheduledHelp: false,
      nonCustomerActivitiesHelp: false,
      attemptUpdateHelp: false,
      latestDateHelp: false,
      dateRangeHelp: false,
      extractEnvHelp: false,
      parentResourceHelp: false,
      parentBucketHelp: false,
      removeNonCustomerHelp: false,
      includeChildrenHelp: false,
      removeCancelledHelp: false,
      fieldsHelp: false,
      identifyByHelp: false,
      includeActivityLinkHelp: false,
      includeResourcePrefHelp: false,
    };
  },
  computed: {
    ...mapGetters('storeDataLoader', ['activities', 'activityCount']),
    invalidForm: {
      get() {
        return this.$refs.valObs.$data.flags.invalid;
      },
      set() {
        const invalid = this.$refs.valObs.$data.flags.invalid;
        this.$store.dispatch('storeDataLoader/setInvalid', invalid);
      },
    },
    hideInputs() {
      return !!(
        this.extractEnvironment === 'CSV' || this.extractEnvironment === 'JSON'
      );
    },
    friendlySourceEnvName() {
      return getFriendlyEnvName(
        this.extractEnvironment,
        this.localEnvironmentList,
      );
    },
    resourceParent: {
      get() {
        return this.$store.getters['storeDataLoader/resourceParent'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setResourceParent', val);
      },
    },
    shouldRetrieveActivityLinks: {
      get() {
        return this.$store.getters[
          'storeDataLoader/shouldRetrieveActivityLinks'
        ];
      },
      set(val) {
        this.$store.dispatch(
          'storeDataLoader/setShouldRetrieveActivityLinks',
          val,
        );
      },
    },
    nonCustomerActivityTypes: {
      get() {
        return this.$store.getters['storeDataLoader/nonCustomerActivityTypes'];
      },
      set(val) {
        this.$store.dispatch(
          'storeDataLoader/setNonCustomerActivityTypes',
          val,
        );
      },
    },
    shouldRetrieveResourcePrefs: {
      get() {
        return this.$store.getters[
          'storeDataLoader/shouldRetrieveResourcePrefs'
        ];
      },
      set(val) {
        this.$store.dispatch(
          'storeDataLoader/setShouldRetrieveResourcePrefs',
          val,
        );
      },
    },
    dates: {
      get() {
        return this.$store.getters['storeDataLoader/dates'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setDates', val);
      },
    },
    activityFields: {
      get() {
        return this.$store.getters['storeDataLoader/activityFields'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setActivityFields', val);
      },
    },
    includeNonScheduled: {
      get() {
        return this.$store.getters['storeDataLoader/includeNonScheduled'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setIncludeNonScheduled', val);
      },
    },
    customerActivities: {
      get() {
        return this.$store.getters['storeDataLoader/customerActivityTypes'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setCustomerActivityTypes', val);
      },
    },
    includeChildren: {
      get() {
        return this.$store.getters['storeDataLoader/includeChildren'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setIncludeChildren', val);
      },
    },
    removeCancelled: {
      get() {
        return this.$store.getters['storeDataLoader/removeCancelled'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setRemoveCancelled', val);
      },
    },
    removeNonCustomer: {
      get() {
        return this.$store.getters['storeDataLoader/removeNonCustomer'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setRemoveNonCustomer', val);
      },
    },
    attemptUpdate: {
      get() {
        return this.$store.getters['storeDataLoader/attemptUpdate'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setAttemptUpdate', val);
      },
    },
    extractEnvironment: {
      get() {
        return this.$store.getters['storeDataLoader/extractEnvironment'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setExtractEnvironment', val);
      },
    },
    backToBucket: {
      get() {
        return this.$store.getters['storeDataLoader/backToBucket'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setBackToBucket', val);
      },
    },
    activityLimit: {
      get() {
        return this.$store.getters['storeDataLoader/activityLimit'];
      },
      set(val) {
        this.$store.dispatch('storeDataLoader/setActivityLimit', val);
      },
    },
    loadResults() {
      return this.$store.getters['storeDataLoader/loadResults'];
    },
    userId() {
      return this.$store.getters['storeAuth/userId'];
    },
    // We use this to add the JSON and CSV import options to the list of retrieved environments
    localEnvironmentList() {
      let listIncludingFileLoaders;
      if (this.environmentList) {
        listIncludingFileLoaders = this.environmentList.map((env) => {
          return env;
        });
      }
      listIncludingFileLoaders.push({
        friendlyName: 'JSON',
        name: 'JSON',
        password: null,
        username: null,
        production: false,
      });
      listIncludingFileLoaders.push({
        friendlyName: 'CSV',
        name: 'CSV',
        password: null,
        username: null,
        production: false,
      });
      return listIncludingFileLoaders;
    },
  },
  methods: {
    createCsvTemplate() {
      this.$emit('export-csv-template', 'activities-template');
    },
    handleFileError(err) {
      this.$emit('file-error', err.message);
    },
    onClose() {
      this.$store.dispatch('storeDataLoader/setSidebarOpen', false);
      this.$emit('close', false);
    },
    readyToRetrieve() {
      this.$emit('ready-to-retrieve');
      this.$emit('close', false);
    },
    handleTagRemove(tag) {
      this.$store.dispatch(
        'storeDataLoader/removeNonCustomerActivityType',
        tag,
      );
    },
    storeTags(event) {
      this.activityFields = event;
      this.$store.dispatch('storeDataLoader/setSidebarOpen', true);
    },
    showPropSelector() {
      this.$store.dispatch('storeDataLoader/setSidebarOpen', false);
      this.$buefy.modal.open({
        parent: this,
        component: PropSelector,
        hasModalCard: true,
        props: {
          currentTags: this.activityFields,
          env: this.extractEnvironment,
        },
        events: {
          close: this.storeTags,
        },
      });
    },
    async retrieveActivityTypes() {
      try {
        const result = await retrieveActivityTypes({
          extractEnvironment: this.extractEnvironment,
          customer: this.customer,
          groups: configData.activityMigrator.internalGroups,
          userId: this.userId,
          activities: this.activities,
        });
        await this.$store.dispatch(
          'storeDataLoader/setActivityTypes',
          result.data,
        );
        this.nonCustomerActivityTypes =
          this.$store.getters['storeDataLoader/internalActivityTypes'];
      } catch (loadError) {
        this.error = loadError.message;
      }
    },
  },
});
</script>

<style>
.mt-1 {
  margin-top: 1em;
}

.mt-1 {
  margin-top: 0.25em;
}

.ml-0_25 {
  margin-left: 0.25em;
}

div.top-pad {
  margin-top: 1rem;
}

div.bottom-pad {
  padding-top: 1rem;
}

.scroll-tags {
  overflow-y: auto;
  height: 120px;
}
</style>
