<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t(`What's new in the Activity Migrator`) }}
      </p>
    </header>
    <section class="modal-card-body">
      <p>
        As you can see, the Activity Migrator has been updated to make it easier
        for you to see and work with your data.
      </p>
      <h2 class="is-size-4" style="margin-top: 0.5em">Why the change?</h2>
      <p>
        In the previous version you had a single list of all criteria for
        extracting data, manipulating that data and loading it into your target
        environment. This involved a lot of scrolling and feedback was that it
        wasn't easy enough to use.
      </p>
      <p>
        In this version we have revamped the user interface to include two new
        sidebars accessed using the Extract Criteria and Load Criteria buttons
        at the top of the screen. The Extract Criteria sidebar on the left
        focuses on the information you want to extract from your source
        environment or CSV, whereas the Load Criteria sidebar on the right
        focuses on manipulating and loading the data into your target
        environment.
      </p>
      <h2 class="is-size-4" style="margin-top: 0.5em">Extracting activities</h2>
      <p>
        Once you have set the criteria for the data extraction, click the Save
        button, tap escape, or click outside of the Extract Sidebar to close it.
        Then click the Extract Activities button at the top of the screen to
        extract the data.
      </p>
      <h2 class="is-size-4" style="margin-top: 0.5em">
        Manipulating and loading activities
      </h2>
      <p>
        All the usual options for changing SLAs and dates, and removing
        durations are present in the Load Sidebar on the right-hand side of the
        screen. Set your options and click the Save button, tap escape, or click
        outside of the Load Sidebar to close it. Discarding the data and
        removing checked rows is performed using the buttons underneath the
        table of data. When you're finished manipulating your data, click Load
        Activities at the top of the screen to load them into your target
        environment
      </p>
      <b-button style="margin-top: 1em" type="is-primary" @click="onClose"
        >Close</b-button
      >
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ActivityMigratorWhatsNew',
  methods: {
    onClose() {
      this.$parent.close();
    },
  },
});
</script>

<style></style>
