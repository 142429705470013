<template>
  <div class="modal-card" style="width: auto; height: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Select the activity properties to extract') }}
      </p>
    </header>
    <section class="modal-card-body">
      <b-field>
        <b-taginput
          v-model="selectedTags"
          class="scroll-tags"
          type="is-info"
          :data="filteredTags"
          field="title"
          :allow-new="true"
          :allow-duplicates="false"
          :autocomplete="true"
          icon="tag"
          @typing="getFilteredTags"
          @add="addProp"
          @remove="removeProp"
        ></b-taginput>
      </b-field>
      <p class="has-text-grey is-size-5">
        {{ $t('Select props help') }}
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button type="is-primary" @click="$emit('close', updatedTags)">
        {{ $t('Done') }}
      </b-button>
      <div v-if="error">{{ error }}</div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { getProperties } from '@/services/activityMigratorService.js';

export default defineComponent({
  name: 'PropSelector',
  props: {
    currentTags: {
      type: Array,
      required: true,
    },
    env: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      properties: [],
      error: null,
      filteredTags: this.currentTags,
      selectedTags: this.currentTags,
      tagKeys: [],
      updatedTags: [],
    };
  },
  async mounted() {
    await this.retrieveProperties();
    Object.assign(this.selectedTags, this.currentTags);
    this.selectedTags.forEach((tag, index) => {
      let propObj = this.findSelectedProp(tag);
      this.selectedTags[index] = propObj;
    });
    Object.assign(this.updatedTags, this.currentTags);
    this.$forceUpdate();
  },
  methods: {
    findSelectedProp(prop) {
      for (const element of this.properties) {
        if (element.label === prop.label) {
          return element;
        }
      }
    },
    addProp(prop) {
      let propObj = this.findSelectedProp(prop);
      this.updatedTags.push(propObj);
    },
    removeProp(prop) {
      this.updatedTags.forEach((tag, index) => {
        if (tag.label === prop.label) {
          this.updatedTags.splice(index, 1);
        }
      });
    },
    getTagKeys(propertyArray) {
      return propertyArray.map((prop) => {
        return prop.label;
      });
    },
    getFilteredTags(text) {
      if (this.properties.length > 0) {
        this.filteredTags = this.properties.filter((option) => {
          return option.title.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
      } else {
        return [];
      }
    },
    async retrieveProperties() {
      try {
        const data = await getProperties({
          env: this.env,
          customer: this.customer,
          userId: this.userId,
        });
        this.properties = data || [];
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },
  },
});
</script>

<style scoped>
.scroll-tags {
  overflow-y: auto;
  height: 250px;
}
</style>
