<!--
  Allow for upload of activity via a file
-->
<template>
  <div>
    <b-field>
      <input type="file" accept=".json" @change="loadTextFromFile" />
    </b-field>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'JSONLoader',
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        let jsonData;
        try {
          jsonData = JSON.parse(e.target.result);
          this.$store.dispatch('storeDataLoader/storeActivities', jsonData);
        } catch (err) {
          console.error(err);
          this.$emit('file-error', err);
        }
      };
      reader.readAsText(file);
    },
  },
});
</script>
